import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './css/Contact.css';
import logo from './img/logo.png';

const ContactPage = () => {
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaQuestion, setCaptchaQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    generateCaptcha();
  }, []);

  // Generates a simple math captcha
  const generateCaptcha = () => {
    const a = Math.floor(Math.random() * 10) + 1;
    const b = Math.floor(Math.random() * 10) + 1;
    setCaptchaQuestion(`What is ${a} + ${b}?`);
    setCorrectAnswer(a + b);
    setCaptchaAnswer("");
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  // Format phone number as the user types
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(formatPhoneNumber(value));
  };

  const formatPhoneNumber = (value) => {
    if (value.length < 4) {
      return value;
    } else if (value.length < 7) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  };

  const handleCaptchaChange = (e) => {
    setCaptchaAnswer(e.target.value);
  };

  // Change handler for the reason field
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate captcha
    if (parseInt(captchaAnswer, 10) !== correctAnswer) {
      alert("Captcha answer is incorrect. Please try again.");
      generateCaptcha();
      return;
    }

    // Collect form data using the state for 'reason'
    const formData = {
      firstName: e.target['first-name'].value,
      lastName: e.target['last-name'].value,
      company: e.target.company.value,
      phone: phone,
      email: e.target.email.value,
      reason: reason,
    };

    try {
      const response = await fetch('/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Update the state to show confirmation only if the email was sent successfully
        setSubmitted(true);
      } else {
        alert('There was an error sending your message. Please try again.');
      }
    } catch (error) {
      console.error('Error sending contact form:', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <div className="contact-page">
      <header className="landing-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="header-right">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/store">Products</Link></li>
              <li><Link to="/FAQ">FAQ</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <button onClick={redirectToLogin} className="login-button-horizontal">Login</button>
        </div>
      </header>

      <div className="contact-container">
        {submitted ? (
          <div className="thank-you-message">
            <h2>Thank You!</h2>
            <p>Your message has been received. We will get back to you shortly.</p>
          </div>
        ) : (
          <>
            <h1>Contact Us</h1>
            <p>Feel free to reach out directly or provide your contact information, and we will contact you directly.</p>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="contact-form-row">
                <div className="contact-form-group">
                  <input type="text" id="first-name" name="first-name" placeholder="First Name" required />
                </div>
                <div className="contact-form-group">
                  <input type="text" id="last-name" name="last-name" placeholder="Last Name" required />
                </div>
              </div>

              <div className="contact-form-group">
                <input type="text" id="company" name="company" placeholder="Company" required />
              </div>

              <div className="contact-form-group">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="(000) 000-0000"
                  value={phone}
                  onChange={handlePhoneChange}
                  maxLength="14"
                  required
                />
              </div>

              <div className="contact-form-group">
                <input type="email" id="email" name="email" placeholder="Email" required />
              </div>

              <div className="contact-form-group">
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Reason for Contacting"
                  required
                  rows="4"
                  value={reason}
                  onChange={handleReasonChange}
                ></textarea>
              </div>

              <div className="contact-form-group captcha-group">
                <label>{captchaQuestion}</label>
                <input 
                  type="text" 
                  value={captchaAnswer} 
                  onChange={handleCaptchaChange} 
                  placeholder="Your Answer" 
                  required 
                />
              </div>

              <button type="submit" className="contact-submit-button">Submit</button>
            </form>
          </>
        )}
      </div>

      <footer className="landing-footer">
        <p>© 2024 Your Company. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default ContactPage;
