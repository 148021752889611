import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import './css/Home.css';
import './css/Nav.css';
import companynameimage from './img/companyname.png';
import logo from './img/logo.png';
import lockimg from './img/lockimg.png';
import websiteimg from './img/websiteimg.png';
import phoneandlock from './img/phoneandlock.png';
import phone from './img/phone.png';
import clock from './img/clock.png';
import businessphoto from './img/businessphoto.png';

const LandingPage = () => {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  return (
    <div className="landing-page">
      {/* Header */}
      <header className="landing-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="header-right">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/store">Products</Link></li>
              <li><Link to="/FAQ">FAQ</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <button onClick={redirectToLogin} className="login-button-horizontal">Login</button>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <img src={companynameimage} alt="Company Name" className="title-company-logo" />
          <p>We provide an automated rental solution for any equipment in your business</p>
          {/* Updated Learn More button to navigate to FAQ page */}
          <button className="cta-button" onClick={() => history.push('/FAQ')}>Learn More</button>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features-section">
        <h2>Our Solutions</h2>
        <div className="features-grid">
          <div className="feature-item">
            <img src={lockimg} alt="KT Smart Locks" />
            <h3>KT Smart Locks</h3>
            <p>Unlock equipment through an online storefront. Customers scan a QR code, connect to our store, and make a payment to unlock the equipment.</p>
          </div>
          <div className="feature-item">
            <img src={websiteimg} alt="KT Software" />
            <h3>KT Software</h3>
            <p>Control the rental process through your online portal. Adjust rental prices or offer equipment for free, with real-time tracking and management.</p>
          </div>
        </div>
      </section>

      {/* Split Section */}
      <section className="split-section">
        <div className="split-content">
          <h2>Prevent Equipment Loss</h2>
          <p>Is your equipment tied up by a few, leaving others without access? Our smart locks ensure fair usage and automated returns, so your equipment is always available when needed. With 24/7 access and built-in accountability, you can manage operations effortlessly.</p>
          {/* Button removed as per your request */}
        </div>
        <div className="split-image">
          <img src={businessphoto} alt="Side Visual" />
        </div>
      </section>

      {/* Cards Section */}
      <section className="cards-section">
        <h2>All-in-One Package</h2>
        <div className="cards-grid">
          <div className="card-item">
            <img src={phoneandlock} alt="Card 1" />
            <h3>Stay in control</h3>
            <p>Payment is processed upfront before tenants can utilize the amenity</p>
          </div>
          <div className="card-item">
            <img src={phone} alt="Card 2" />
            <h3>Hands off</h3>
            <p>After setup your tenants do the rest from their mobile phone! There's no extra work for your managers</p>
          </div>
          <div className="card-item">
            <img src={clock} alt="Card 3" />
            <h3>Works anywhere</h3>
            <p>Tenants can rent online at anytime! Now they're incentivized to return the equipment</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Ready to Streamline Your Rentals?</h2>
        <p>Join countless businesses who have transformed their equipment rental process.</p>
        {/* Updated Get Started button to navigate to Contact Us page */}
        <button className="cta-button" onClick={() => history.push('/contact')}>Get Started</button>
      </section>

      {/* Footer */}
      <footer className="landing-footer">
        <div className="footer-content">
          <p>© 2024 Your Company. All rights reserved.</p>
          <nav>
            <ul>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
