import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/admincss/AdminClientManager.css';

const ClientManager = () => {
  const [clients, setClients] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/clients', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients', error);
      }
    };

    fetchClients();
  }, []);

  const handleAddClient = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/admin/add-client',
        { email, password },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setClients([...clients, response.data]);
      setMessage('Client added successfully');
      setEmail('');
      setPassword('');
    } catch (error) {
      console.error('Error adding client', error);
      setMessage('Error adding client');
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/admin/delete-client',
        { clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setClients(clients.filter((client) => client.id !== clientId));
      setMessage('Client deleted successfully');
    } catch (error) {
      console.error('Error deleting client', error);
      setMessage('Error deleting client');
    }
  };

  return (
    <div className="client-manager">
      <h3>Client Manager</h3>
      {message && <p className="message">{message}</p>}

      <div className="add-client">
        <h4>Add a Client</h4>
        <div className="form-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button onClick={handleAddClient}>Add Client</button>
      </div>

      <div className="client-list">
        <h4>Clients</h4>
        <ul>
          {clients.map((client) => (
            <li key={client.id}>
              <span>{client.email}</span>
              <button onClick={() => handleDeleteClient(client.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClientManager;
