import React from 'react'; 
import '../css/clientcss/DeviceSetup.css'; // Correct CSS path

const DeviceSetup = () => {
  return (
    <div className="device-setup-container">
      <div className="device-setup-box">
        
        {/* Centered header remains unchanged */}
        <h1 className="device-setup-header">Device Setup Instructions</h1>

        {/* Content now only contains the text */}
        <div className="device-setup-content">
          <div className="device-setup-text">
            {/* Introduction remains centered */}
            <p className="device-setup-intro">Follow these steps to set up your device</p>
            
            <ol>
              <li>To get started you will need a wall outlet, and a phone or laptop.</li>
              <li>First, plug your device into an outlet and connect the shackle to the device.</li>
              <li>For these next few steps it helps to open your wifi settings on your phone or laptop.</li>
              <li>Flip the device over and locate the setup button hole.</li>
              <li>You will need something small and thin to reach the button. Insert it into the hole until you hear a click and hold for 10 seconds.</li>
              <li>This enables the wifi portal and you will see KT-Setup-Portal 2 appear as an available network.</li>
              <li>Click on this network and open a new web page.</li>
              <li>In the address bar enter 192.168.4.1 and press enter.</li>
              <li>After the page loads you should see the Wifi Manager Portal, click on configure wifi.</li>
              <li>Select your WIFI network, enter the password, and click save.</li>
              <li>You may now close the web page and reconnect to your normal wifi network</li>
              <li>Congrats! Your device should now be available to connect in the "Device Management" section of your account.</li>
              <li>Simply enter the device number provided on the device when creating your product.</li>
              <li>Once you finish creating your product, the status at the bottom of the product card will be "Locked".</li>
              <li>If you encounter any issues, try going through the process again and double check the wifi credentials and lock number are correct.</li>
              <li>If you are still encountering issues, please reach out to us by clicking on the link below.</li>
            </ol>
            
            {/* Support button remains unchanged */}
            <div className="device-setup-support">
              <a href="/support" className="support-link">Contact Support</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceSetup;
