import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/admincss/AdminProductManager.css';

const AdminProductManager = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch all clients when the component mounts
    const fetchClients = async () => {
      try {
        const response = await axios.get('/admin/clients');
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = async (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);

    if (clientId) {
      setIsLoading(true);
      try {
        // Fetch products for the selected client
        const response = await axios.get(`/client-products/get-products/${clientId}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setProducts([]);
    }
  };

  return (
    <div className="admin-product-manager">
      <h1>Admin Product Manager</h1>
      <div className="client-select">
        <label htmlFor="client-select">Select Client:</label>
        <select id="client-select" onChange={handleClientChange}>
          <option value="">--Select a Client--</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.email}
            </option>
          ))}
        </select>
      </div>
      <div className="products-section">
        <h2>
          Products for{' '}
          {selectedClient
            ? clients.find((client) => client.id === selectedClient)?.email
            : 'Select a Client'}
        </h2>
        {isLoading ? (
          <p className="loading">Loading...</p>
        ) : (
          <ul className="product-list">
            {products.map((product) => (
              <li key={product.id} className="product-item">
                <div className="product-details">
                  <p>
                    <strong>{product.product_name}</strong>
                  </p>
                  <p>
                    Deposit: {product.deposit_amount} - Hourly Amount:{' '}
                    {product.hourly_amount} - Device Number: {product.device_number}
                  </p>
                </div>
                {product.product_image && (
                  <div className="product-image">
                    <img src={product.product_image} alt={product.product_name} />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminProductManager;
